<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Quản Lý Nhóm Khách Hàng</b-card-title>
      <b-card-sub-title>
        <b-button variant="success" :to="{name: 'users-group-create'}"><feather-icon icon="PlusIcon" />Thêm Nhóm Khách Hàng</b-button>
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <app-collapse>
        <app-collapse-item v-for="(group, k) in groups" :key="k" :title="group.name">
          <b-table-filter
            :title="'Danh Sách ' + group.name"
            :items="items"
            :fields="fields"
          />
        </app-collapse-item>
      </app-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCardSubTitle, BCardTitle, BCard, BCardHeader, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'

export default {
  created() {
    this.groups = [{
      name: 'Khách Cũ',
      items: [
        {
          fullName: 'Danh Sinh 2',
          phone: '096 998 5002',
          createdAt: '1/8/2022',
        },
      ],
    },
    {
      name: 'Khách Quen',
      items: [
        {
          fullName: 'Danh Sinh',
          phone: '096 998 5002',
          createdAt: '1/8/2022',
        },
      ],
    },
    ]
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCardSubTitle,
    BCardHeader,
    BCardTitle,
    BCard,
    BButton,
    FeatherIcon,
    BTableFilter
},
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'fullName',
          label: 'Tên Khách Hàng',
        },
        {
          key: 'phone',
          label: 'Số Điện Thoại',
        },
        {
          key: 'createdAt',
          label: 'Ngày Đăng Ký',
        },
      ],
      groups: [],
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" >

</style>